import React from "react";

const ProfileIcon = () => {
    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_499_693)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7 7C7 3.15 10.15 0 14 0C20.125 0 23.275 7.525 19.075 12.075C14.525 16.275 7 13.125 7 7ZM28 24.325V28H0V24.675C0 20.825 6.125 17.675 14 17.675C21.875 17.675 28 20.65 28 24.325Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_499_693">
                    <rect width="28" height="28" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default ProfileIcon;

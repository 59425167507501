import React from "react";

const PaperIcon = () => {
    return (
        <svg
            width="21"
            height="40"
            viewBox="0 0 21 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <rect
                opacity="0.72"
                width="21"
                height="40"
                fill="url(#pattern70)"
            />
            <defs>
                <pattern
                    id="pattern70"
                    patternContentUnits="objectBoundingBox"
                    width="1"
                    height="1"
                >
                    <use
                        xlinkHref="#image0_499_118"
                        transform="matrix(0.0501253 0 0 0.0263158 -0.00125313 0)"
                    />
                </pattern>
                <image
                    id="image0_499_118"
                    width="20"
                    height="38"
                    xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAmCAYAAADEO7urAAABX2lDQ1BJQ0MgUHJvZmlsZQAAKJFtkM1LAlEUxc+UYWhBRLRKEIJAmCQ0KNqZ0AcZDKb0QZtxtDHQ8TFORNSm/6BV0L5atgmaIIg2LaKgRVAQrWudkZuy131ajVYXLvfH4bzLfQdoalUZy7kA5A3LjI+P+ufmF/zuJ3jhgwe9CKhakUUUJUYWfM/GKt9CEvOmX+x6Pvh4LfHz00Xf3sUYPyr99TeUJ50pajTfqYMaMy1AkomVVYsJ3iDuMuko4i3Beo13BadqfFz1JOJR4iviDi2rpokfiOVUna7XcT63on3dIK5vyxjJGZqd1D1QMIEY/AhhiHoaw5TP//7Bqj+KAhjWYGIZOrKw6G2EFIYcMsSTMKAhCLm6c4A6LHL+nZ+jrV8DI5tA85SjJZKAvUNfvXS0vkOg/RE46Waqqf6kKpVdxaVwqMZeG2jZ5vxlFnAHgMod528255V92n8PnJU/AWlTZ4BG11toAAAAOGVYSWZNTQAqAAAACAABh2kABAAAAAEAAAAaAAAAAAACoAIABAAAAAEAAAAUoAMABAAAAAEAAAAmAAAAAEr0OSEAAANISURBVEgNrZbLT1NBFIdnbnt7+6A8UgXkERMTXhploSay0BjYGVZECGjURKMrX4n+CeiGtXGhLnyEFIzRuDAu1JhoJEYWCAEkRnkEpFBEKODtfc14JvEO0/YWrtR2MWfOmfPNbx5nWnTgk67te5foq3778zyiFKMcP7hhgFKbYZomMbTkWFIzo6sLhV3TbVi1Y27bFKCYRAhBmqpOG4b+fNkiN6ebts2I8Wx2VqCYwNagJdVFgL9ew9qN8cMln8W4aLsCignM1jT9t6kl+3TTvP2lMfJEjG8JKAIMwwC2OmhZxv2R+citnIEiXNc0VRIdudo+RQn8VyATJK0sLT1Mquo8Ifw65iSUV8beQVpEEomrPo+3VVb8NR5J2pJ6DhRl7e+nMqH6cWSRC7JXPuTxevxifCPbEZiSAPV9cAA1wN24BKpbZZ/PkxJP63jT+pldjOlU7+gKJqQeI+wBIAqEwyiYF0ZKIIDSFaX3U4GgrjQ6cg1j3AkBJTUIJ+r1ADgfhWACJRhEEoYp0wfZ/UjvWLlMrEcw4Kjt26jFcIahcMF3x5Msjg6fBNiwWxg8HgmLWJe/fiytSlFY+HS80K+p98DZspEaMQawXstDr8Tb9sSYnwN39IwcoZT2wC6UignZbCiDCUqkc3Mnat+IY/iSi7YXP4PNdwGjOhRVZyxfrk2HMTC/NrChYa/sQ/Ef0whlq0KKPljYd3q+o+qbqEq0OZA52fGjsgqAwmu//lPD+HEIX4917H4gJjvZfMl2kEGLyythdzEDUfjeSSqB6lj75jDGSFFoQ4N5IVRSUWnMzM00xltq3tt+N22GQjspEArJlWU7u3f10wLb56blQFhbxlEofqUyT1156QZkj1kHUkpsp9jCVXL9dLE8DlxdWT1lWlaGShHuxubAsabI41+L8fZcoRzIZl9bWJyNTU5opmG4EeM4hgNLoqPNUNmvTF33z05NIg79x03gQImSLrjNPjatBQoZVNd14flwFJTh5ED4ZyiLUQaF5QM0uST6N7M50GmgZVlDywvzZ5xi2XyOpfd38F14oi6iY1VatmQnvxNQIxSfneuo63ZK2MyXBqRTRJKa59rqhjZLzBYX9/CFqgTrc4GxSf4A2tVLFUlEVOcAAAAASUVORK5CYII="
                />
            </defs>
        </svg>
    );
};

export default PaperIcon;

import React from "react";

const StackIcon = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_499_1258)">
                <path d="M16 0L0 11.6L16 23.2L32 11.6L16 0Z" fill="white" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 26.6L28.4 17.8L32 20.4L16 32L0 20.4L3.6 17.8L16 26.6Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_499_1258">
                    <rect width="32" height="32" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default StackIcon;

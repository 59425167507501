import React from "react";

const RightArrowIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="36"
            viewBox="0 0 32 36"
            fill="none"
        >
            <path
                d="M32 18L-1.57361e-06 36L-1.09097e-06 24.9584L11.0476 18L-4.94758e-07 11.3187L0 -1.39876e-06L32 18Z"
                fill="black"
            />
        </svg>
    );
};

export default RightArrowIcon;

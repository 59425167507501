export const LOGIN_FORM = "LOGIN_FORM";
export const REGISTER_FORM = "REGISTER_FORM";

export const apperanceMenus = [
    "Eye",
    "Hair",
    "Face Shape",
    "Eyebrows",
    "Facial Hair",
    "Glasses",
    "Face Mask",
];

export const outfitMenus = ["Upper", "Lower", "Footwear", "Headwear"];

export const assetEntities = {
    Upper: "top",
    Lower: "bottom",
    Footwear: "footwear",
    Headwear: "headwear",
    Eyebrows: "eyebrows",
    "Facial Hair": "beard",
    "Face Shape": "faceshape",
    Hair: "hair",
    Glasses: "glasses",
    Eye: "eye",
    "Face Mask": "facemask",
};

export const assetTypes = {
    beard: "beardStyle",
    eye: "eyeColor",
    eyebrows: "eyebrowStyle",
    faceShape: "faceShape",
    glasses: "glasses",
    hair: "hairStyle",
    headwear: "headwear",
    top: "top",
    bottom: "bottom",
    footwear: "footwear",
    skinColor: 5,
    skinColorHex: "#cf927a",
    facemask: "faceMask",
};

export const AVATAR_NODES_FROM_ASSET_TYPE = {
    Eye: "Wolf3D_Head",
    Hair: "Wolf3D_Head",
    "Face Shape": "Wolf3D_Head",
    Eyebrows: "Wolf3D_Head",
    "Facial Hair": "Wolf3D_Head",
    Glasses: "Wolf3D_Head",
    "Face Mask": "Wolf3D_Head",
    Upper: "Wolf3D_Outfit_Top",
    Lower: "Wolf3D_Outfit_Bottom",
    Footwear: "Wolf3D_Outfit_Footwear",
    Headwear: "Wolf3D_Head",
    reset: "Wolf3D_Body",
};

export const CHARACTER_CREATION = "CHARACTER_CREATION";
export const IDEA_CREATOR = "IDEA_CREATOR";
export const CHECKOUT = "CHECKOUT";

export const HDR_ENVIRONMENT = "/image/hdr/shutterstock_2257281257.hdr";
